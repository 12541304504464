import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import BillingAddInputField from "../components/BillingAddInputField";
import DatePicker from "react-datepicker";
import moment from "moment";
import PopUpModal from "../components/PopUpModal";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import Webcam from "react-webcam";

const NewRegister = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  // console.log("state", state);
  const storedBranchNames = localStorage.getItem("branchNames");
  const branchNameArray = JSON.parse(storedBranchNames) || [];

  // Set the initial state based on the branchNameArray
  const [selectedHostel, setSelectedHostel] = useState(
    state?.selectHostel || branchNameArray[0] || ""
  );

  const [count, setCount] = useState(0);
  const [imagePreviews, setImagePreviews] = useState({
    aadharPhoto: null,
    empIdImage: null,
    uploadImage: null,
  });

  const [showCamera, setShowCamera] = useState(false);
  const [currentField, setCurrentField] = useState(null);
  const webcamRef = useRef(null);
  const fileInputRefs = {
    aadharPhoto: useRef(null),
    empIdImage: useRef(null),
    uploadImage: useRef(null),
  };

  const [addRegisterPopup, setRegisterPopup] = useState(null);
  const [roomStatus, setRoomStatus] = useState(null);
  const [errroMessage, setErrorMessage] = useState("");
  const [showErrorPopUp, setErrorPopUp] = useState(null);
  // console.log("HOSTEL NAME : ", hostelName);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const getViewRoom = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/rooms/getAllNewRoom/${selectedHostel}`
    );
  };
  const getViewBedNo = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/newregister/${selectedHostel}/lastBedNo`
    );
  };
  // const { mutate, isLoading, isError, isSuccess } = useMutation(
  //   (postData) =>
  //     axios.post(
  //       `${process.env.REACT_APP_HOST}/newregister/${selectedHostel}`,
  //       postData
  //     ),
  //   {
  //     onSuccess: () => {
  //       console.log("data added successfully...");
  //       setRegisterPopup(1);
  //     },
  //     onError: (error) => {
  //       if (error?.response?.data?.status == 500) {
  //         alert("Internal Server Error.....");

  //       }
  //       if (error?.response) {
  //         // Server responded with a status code
  //         const { status, data } = error?.response;
  //         setErrorMessage(data);
  //         console.log("Error Response:", data);
  //         setErrorPopUp(1);
  //       } else {
  //         console.log("Unexpected Error:", error?.message);
  //       }
  //     },
  //   }
  // );
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(
        `${process.env.REACT_APP_HOST}/newregister/${selectedHostel}`,
        postData
      ),
    {
      onSuccess: () => {
        console.log("Data added successfully...");
        setRegisterPopup(1);
      },
      onError: (error) => {
        console.log("error");
        // Prevent further error handling from propagating
        if (error?.response?.status === 500) {
          alert(
            "Oops! Something went wrong on our end. Please try again later."
          );
        } else if (error?.response) {
          const { data } = error?.response;
          setErrorMessage(data?.message || "An error occurred");
          console.log("Error Response:", data);
          setErrorPopUp(1);
        } else {
          console.log("Unexpected Error:", error.message);
          alert("An unexpected error occurred. Please try again.");
        }
        // Prevent propagation to error boundary
        return Promise.reject();
      },
    }
  );

  // Handle the selection change
  const handleHostelChange = (e) => {
    const newValue = e.target.value;
    setSelectedHostel(newValue);
    setValue("selectHostel", newValue);
  };
  const { data } = useQuery(["getViewRoom", selectedHostel], getViewRoom, {
    enabled: !!selectedHostel, // Ensure the query runs only if selectedHostel is not empty
  });
  let details = data && data?.data;
  const { data: bednumber } = useQuery(
    ["getViewBedNo", selectedHostel],
    getViewBedNo,
    {
      enabled: !!selectedHostel, // Ensure the query runs only if selectedHostel is not empty
    }
  );
  let registerBedNo = bednumber && bednumber?.data;
  let roomNumber = details?.map((dd, i) => {
    return dd?.roomNo;
  });
  // console.log("ROOMNUMBER", roomNumber);
  useEffect(() => {
    setValue("roomNo", state?.roomNo);
    setValue("bedNo", registerBedNo);
    // setValue("selectHostel", state?.selectHostel);
    if (state?.selectHostel) {
      setSelectedHostel(state?.selectHostel);
      setValue("selectHostel", state?.selectHostel); // Update the react-hook-form field
    }
  }, [setValue, state?.selectHostel, registerBedNo, selectedHostel]);

  // Image Upload

  // const handleFileChange = (type, onChange) => (event) => {
  //   const selectedFile = event.target.files[0];
  //   if (selectedFile) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       onChange(reader.result); // Set the Base64 string
  //       setImagePreviews((prev) => ({
  //         ...prev,
  //         [type]: reader.result,
  //       })); // Update the image preview
  //     };
  //     reader.readAsDataURL(selectedFile); // Convert to Base64
  //   } else {
  //     onChange(null); // Handle file removal
  //     setImagePreviews((prev) => ({
  //       ...prev,
  //       [type]: null,
  //     })); // Clear the image preview
  //   }
  // };
  const handleFileChange = (type, onChange) => (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Remove the prefix
        onChange(base64String); // Set the Base64 string without prefix

        setImagePreviews((prev) => ({
          ...prev,
          [type]: reader.result, // Keep the full string for preview
        }));
      };
      reader.readAsDataURL(selectedFile); // Convert to Base64
    } else {
      onChange(null); // Handle file removal
      setImagePreviews((prev) => ({
        ...prev,
        [type]: null, // Clear the image preview
      })); // Clear the image preview
    }
  };

  const handleRemoveImage = (type, onChange) => () => {
    onChange(null); // Clear the Base64 string
    setImagePreviews((prev) => ({
      ...prev,
      [type]: null,
    })); // Clear the image preview
  };

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc && currentField) {
      setImagePreviews((prev) => ({
        ...prev,
        [currentField]: imageSrc,
      }));
      setValue(currentField, imageSrc); // Update form state with Base64 string
      setShowCamera(false); // Hide camera after capturing
    }
  };

  const renderCamera = () => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "black", // optional, to give a better visual
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        // width={300}
        width="65%"
        height="65%"
        videoConstraints={{
          facingMode: "environment", // Change to "user" for front camera
        }}
      />
      <div>
        <button onClick={captureImage} className="camera-btn">
          Capture Image
        </button>
        <button onClick={() => setShowCamera(false)} className="camera-btn">
          Close Camera
        </button>
      </div>
    </div>
  );

  const onSubmit = (data) => {
    let formData = {
      name: data?.name || "",
      mobileNo: data?.mobileNo || "",
      emailId: data?.emailId || "",
      roomNo: data?.roomNo || "",
      emergencyNo: data?.emergencyNo || "",
      dateOfBirth: moment(data?.dateOfBirth).format("YYYY-MM-DD") || "",
      dateOfJoining: moment(data?.dateOfJoining).format("YYYY-MM-DD") || "",
      address: data?.address || "",
      bedNo: data?.bedNo || "",
      status: data?.status || "",
      selectHostel: data?.selectHostel || "",
      selectFoodOption: data?.selectFoodOption || "",
      advanceAmount: data?.advancePayment || "",
      rentForMonth: data?.rentForMonth || "",
      proffesionType: data?.proffesionType || "",
      aadharNumber: data?.aadharNumber || "",
      parentialOrGuardian: data?.parentialOrGuardian || "",
      dateOfLeaving:
        (data?.dateOfLeaving &&
          moment(data?.dateOfLeaving).format("YYYY-MM-DD")) ||
        null,
      familyNumber: data?.familyNumber || "",
      uploadPhoto: data?.uploadImage || "",
      uploadAadhar: data?.aadharPhoto || "",
      uploadEmployeeIdCard: data?.empIdImage || "",
      startDate: null,
      endDate: null,
    };
    if (Array.isArray(roomNumber) && formData?.roomNo != null) {
      const isAvailable = roomNumber?.includes(formData?.roomNo);

      if (isAvailable && selectedHostel) {
        mutate(formData);
      } else {
        setRoomStatus(1);
      }
    }
  };

  // Function to get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const { status } = watch();
  return (
    <>
      <section className="registrationMainContainer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 p-0 m-0">
              <Sidebar />
            </div>
            <div className="col-lg-10  px-0  m-0">
              <div className="row py-2 px-2 m-0 new-register-heading-block">
                <div className="col-12 col-sm-6 col-md-6 my-1 px-1">
                  <div className="new-register-title-block">
                    <img
                      src="/assets/images/arrow-blue.png"
                      alt="no-image"
                      className="arrow-img-icon"
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                    <h5 className="register-title pt-2">NEW REGISTER</h5>
                  </div>
                </div>
                <div className="col-12 col-sm-6 d-flex justify-content-between justify-content-md-end align-items-center gap-2 my-1 px-1">
                  <Controller
                    name="selectHostel"
                    control={control}
                    defaultValue={selectedHostel}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <select
                        {...field}
                        value={selectedHostel}
                        onChange={handleHostelChange}
                        className={`selectDropDownNewRoom ${
                          errors.selectHostel ? "error" : ""
                        }`}
                      >
                        <option value="">Branch Name</option>
                        {branchNameArray.map((branchName) => (
                          <option key={branchName} value={branchName}>
                            {branchName}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  <button
                    className="view-register-btn"
                    onClick={() => {
                      navigate("/viewRegister");
                    }}
                  >
                    VIEW REGISTER
                  </button>
                </div>
              </div>

              <div className="register-form-main-block">
                <div className="register-form-block my-3">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          NAME <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="name"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Name"
                                type="text"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          EMAIL ID <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="emailId"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Email Id"
                                type="emailid"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          MOBILE NUMBER <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="mobileNo"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Mobile Number"
                                type="tele"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          ALTERNATIVE NUMBER{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="emergencyNo"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Alternative Number"
                                type="tele"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          PROFESSION TYPE{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          {/* <Controller
                            control={control}
                            name="proffesionType"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Profession Type"
                                type="text"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          /> */}
                          <Controller
                            name="proffesionType"
                            control={control}
                            defaultValue="Student"
                            rules={{ required: true }}
                            render={({ field }) => (
                              <select
                                {...field}
                                className={`newRegisterInput ${
                                  errors.status ? "error" : ""
                                }`}
                              >
                                <option value="">Profession Type</option>
                                <option value="Student">Student</option>
                                <option value="Employee">Employee</option>
                              </select>
                            )}
                          />
                        </div>
                      </div>

                      {/* <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          AADHAR NUMBER <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="aadharNumber"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Aadhar Number"
                                type="number"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div> */}
                    </div>
                    {watch("proffesionType") == "Student" ? (
                      <>
                        <div className="new-register-input-block">
                          <div className="register-container">
                            <label className="BillingAddInputLabel --new-register-label-two">
                              UPLOAD PHOTO{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="newRegisterInputBlockTwo  p-0 m-0">
                              <Controller
                                name="uploadImage"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <div className="d-flex align-items-center gap-1 flex-wrap">
                                      <input
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRefs?.uploadImage}
                                        onChange={handleFileChange(
                                          "uploadImage",
                                          field.onChange
                                        )}
                                        style={{ display: "none" }} // Hide the file input
                                      />
                                      <div
                                        onClick={() => {
                                          setCurrentField("uploadImage");
                                          fileInputRefs?.uploadImage?.current?.click(); // Trigger file input
                                        }}
                                        className="upload-file-block"
                                      >
                                        <span className="upload-file-name">
                                          Click here to upload a photo
                                        </span>
                                        <div className="upload_icon">
                                          <img
                                            src="/assets/images/upload.png"
                                            alt="no-image"
                                            className="image_icon"
                                          />
                                        </div>
                                      </div>

                                      <span style={{ fontSize: "12px" }}>
                                        OR
                                      </span>
                                      <div
                                        className="upload_icon"
                                        onClick={() => {
                                          setCurrentField("uploadImage");
                                          setShowCamera(true);
                                        }}
                                      >
                                        <img
                                          src="/assets/images/camera.png"
                                          alt="no-image"
                                          className="image_icon"
                                        />
                                      </div>
                                    </div>
                                    {imagePreviews?.uploadImage && (
                                      <>
                                        <div
                                          style={{
                                            marginTop: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img
                                            src={imagePreviews?.uploadImage}
                                            alt="Aadhar Selected"
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                            }}
                                          />
                                          <button
                                            onClick={handleRemoveImage(
                                              "uploadImage",
                                              field.onChange
                                            )}
                                            style={{
                                              marginLeft: "10px",
                                              color: "red",
                                              border: "none",
                                              background: "none",
                                              cursor: "pointer",
                                            }}
                                          >
                                            X
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              />
                              {showCamera &&
                                currentField === "uploadImage" &&
                                renderCamera()}
                            </div>
                          </div>
                          <div className="register-container">
                            <label className="BillingAddInputLabel --new-register-label-two">
                              UPLOAD AADHAR{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="newRegisterInputBlockTwo  p-0 m-0">
                              <Controller
                                name="aadharPhoto"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <div className="d-flex align-items-center gap-1 flex-wrap">
                                      <input
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRefs?.aadharPhoto}
                                        onChange={handleFileChange(
                                          "aadharPhoto",
                                          field.onChange
                                        )}
                                        style={{ display: "none" }} // Hide the file input
                                      />
                                      <div
                                        onClick={() => {
                                          setCurrentField("aadharPhoto");
                                          fileInputRefs?.aadharPhoto?.current?.click(); // Trigger file input
                                        }}
                                        className="upload-file-block"
                                      >
                                        <span className="upload-file-name">
                                          Click here to upload aadhar
                                        </span>
                                        <div className="upload_icon">
                                          <img
                                            src="/assets/images/upload.png"
                                            alt="no-image"
                                            className="image_icon"
                                          />
                                        </div>
                                      </div>

                                      <span style={{ fontSize: "12px" }}>
                                        OR
                                      </span>
                                      <div
                                        className="upload_icon"
                                        onClick={() => {
                                          setCurrentField("aadharPhoto");
                                          setShowCamera(true);
                                        }}
                                      >
                                        <img
                                          src="/assets/images/camera.png"
                                          alt="no-image"
                                          className="image_icon"
                                        />
                                      </div>
                                    </div>
                                    {imagePreviews?.aadharPhoto && (
                                      <>
                                        <div
                                          style={{
                                            marginTop: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img
                                            src={imagePreviews?.aadharPhoto}
                                            alt="Aadhar Selected"
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                            }}
                                          />
                                          <button
                                            onClick={handleRemoveImage(
                                              "aadharPhoto",
                                              field.onChange
                                            )}
                                            style={{
                                              marginLeft: "10px",
                                              color: "red",
                                              border: "none",
                                              background: "none",
                                              cursor: "pointer",
                                            }}
                                          >
                                            X
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              />
                              {showCamera &&
                                currentField === "aadharPhoto" &&
                                renderCamera()}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="new-register-input-block">
                          <div className="register-container">
                            <label className="BillingAddInputLabel --new-register-label-two">
                              UPLOAD EMPLOYEE ID CARD{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="newRegisterInputBlockTwo  p-0 m-0">
                              <Controller
                                name="empIdImage"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <div className="d-flex align-items-center gap-1 flex-wrap">
                                      <input
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRefs?.empIdImage}
                                        onChange={handleFileChange(
                                          "empIdImage",
                                          field.onChange
                                        )}
                                        style={{ display: "none" }} // Hide the file input
                                      />
                                      <div
                                        onClick={() => {
                                          setCurrentField("empIdImage");
                                          fileInputRefs?.empIdImage?.current?.click(); // Trigger file input
                                        }}
                                        className="upload-file-block"
                                      >
                                        <span className="upload-file-name">
                                          Click here to upload a employee card
                                        </span>
                                        <div className="upload_icon">
                                          <img
                                            src="/assets/images/upload.png"
                                            alt="no-image"
                                            className="image_icon"
                                          />
                                        </div>
                                      </div>

                                      <span style={{ fontSize: "12px" }}>
                                        OR
                                      </span>
                                      <div
                                        className="upload_icon"
                                        onClick={() => {
                                          setCurrentField("empIdImage");
                                          setShowCamera(true);
                                        }}
                                      >
                                        <img
                                          src="/assets/images/camera.png"
                                          alt="no-image"
                                          className="image_icon"
                                        />
                                      </div>
                                    </div>
                                    {imagePreviews?.empIdImage && (
                                      <>
                                        <div
                                          style={{
                                            marginTop: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img
                                            src={imagePreviews?.empIdImage}
                                            alt="Aadhar Selected"
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                            }}
                                          />
                                          <button
                                            onClick={handleRemoveImage(
                                              "empIdImage",
                                              field.onChange
                                            )}
                                            style={{
                                              marginLeft: "10px",
                                              color: "red",
                                              border: "none",
                                              background: "none",
                                              cursor: "pointer",
                                            }}
                                          >
                                            X
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              />
                              {showCamera &&
                                currentField === "empIdImage" &&
                                renderCamera()}
                            </div>
                          </div>
                          <div className="register-container">
                            <label className="BillingAddInputLabel --new-register-label-two">
                              UPLOAD AADHAR{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="newRegisterInputBlockTwo  p-0 m-0">
                              <Controller
                                name="aadharPhoto"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <div className="d-flex align-items-center gap-1 flex-wrap">
                                      <input
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRefs?.aadharPhoto}
                                        onChange={handleFileChange(
                                          "aadharPhoto",
                                          field.onChange
                                        )}
                                        style={{ display: "none" }} // Hide the file input
                                      />
                                      <div
                                        onClick={() => {
                                          setCurrentField("aadharPhoto");
                                          fileInputRefs?.aadharPhoto?.current?.click(); // Trigger file input
                                        }}
                                        className="upload-file-block"
                                      >
                                        <span className="upload-file-name">
                                          Click here to upload aadhar
                                        </span>
                                        <div className="upload_icon">
                                          <img
                                            src="/assets/images/upload.png"
                                            alt="no-image"
                                            className="image_icon"
                                          />
                                        </div>
                                      </div>

                                      <span style={{ fontSize: "12px" }}>
                                        OR
                                      </span>
                                      <div
                                        className="upload_icon"
                                        onClick={() => {
                                          setCurrentField("aadharPhoto");
                                          setShowCamera(true);
                                        }}
                                      >
                                        <img
                                          src="/assets/images/camera.png"
                                          alt="no-image"
                                          className="image_icon"
                                        />
                                      </div>
                                    </div>
                                    {imagePreviews?.aadharPhoto && (
                                      <>
                                        <div
                                          style={{
                                            marginTop: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img
                                            src={imagePreviews?.aadharPhoto}
                                            alt="Aadhar Selected"
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                            }}
                                          />
                                          <button
                                            onClick={handleRemoveImage(
                                              "aadharPhoto",
                                              field.onChange
                                            )}
                                            style={{
                                              marginLeft: "10px",
                                              color: "red",
                                              border: "none",
                                              background: "none",
                                              cursor: "pointer",
                                            }}
                                          >
                                            X
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              />
                              {showCamera &&
                                currentField === "aadharPhoto" &&
                                renderCamera()}
                            </div>
                          </div>
                        </div>
                        <div className="new-register-input-block">
                          <div className="register-container">
                            <label className="BillingAddInputLabel --new-register-label-two">
                              UPLOAD PHOTO{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="newRegisterInputBlockTwo  p-0 m-0">
                              <Controller
                                name="uploadImage"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <div className="d-flex align-items-center gap-1 flex-wrap">
                                      <input
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRefs?.uploadImage}
                                        onChange={handleFileChange(
                                          "uploadImage",
                                          field.onChange
                                        )}
                                        style={{ display: "none" }} // Hide the file input
                                      />
                                      <div
                                        onClick={() => {
                                          setCurrentField("uploadImage");
                                          fileInputRefs?.uploadImage?.current?.click(); // Trigger file input
                                        }}
                                        className="upload-file-block"
                                      >
                                        <span className="upload-file-name">
                                          Click here to upload a photo
                                        </span>
                                        <div className="upload_icon">
                                          <img
                                            src="/assets/images/upload.png"
                                            alt="no-image"
                                            className="image_icon"
                                          />
                                        </div>
                                      </div>

                                      <span style={{ fontSize: "12px" }}>
                                        OR
                                      </span>
                                      <div
                                        className="upload_icon"
                                        onClick={() => {
                                          setCurrentField("uploadImage");
                                          setShowCamera(true);
                                        }}
                                      >
                                        <img
                                          src="/assets/images/camera.png"
                                          alt="no-image"
                                          className="image_icon"
                                        />
                                      </div>
                                    </div>
                                    {imagePreviews?.uploadImage && (
                                      <>
                                        <div
                                          style={{
                                            marginTop: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img
                                            src={imagePreviews?.uploadImage}
                                            alt="Aadhar Selected"
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                            }}
                                          />
                                          <button
                                            onClick={handleRemoveImage(
                                              "uploadImage",
                                              field.onChange
                                            )}
                                            style={{
                                              marginLeft: "10px",
                                              color: "red",
                                              border: "none",
                                              background: "none",
                                              cursor: "pointer",
                                            }}
                                          >
                                            X
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              />
                              {showCamera &&
                                currentField === "uploadImage" &&
                                renderCamera()}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          PARENT/GUARDIAN{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            name="parentialOrGuardian"
                            control={control}
                            defaultValue="Father/Mother" // Set the  default value here
                            render={({ field }) => (
                              <select {...field} className="bookingEditInput">
                                <option value="Father/Mother">
                                  Father/Mother
                                </option>
                                <option value="Local Guardian">
                                  Local Guardian
                                </option>
                              </select>
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                      {watch("parentialOrGuardian") === "Father/Mother" ? ( // Corrected this line
                        <>
                          <div className="register-container">
                            <label className="BillingAddInputLabel --new-register-label-two">
                              FATHER/MOTHER NUMBER{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="newRegisterInputBlockTwo">
                              <Controller
                                control={control}
                                name="familyNumber"
                                render={(field) => (
                                  <BillingAddInputField
                                    {...field}
                                    placeholder="Father/Mother Number"
                                    type="tele" // Changed to "tel" for telephone input
                                    newRegisterInput="newRegisterInput"
                                  />
                                )}
                                rules={{ required: true }}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="register-container">
                            <label className="BillingAddInputLabel --new-register-label-two">
                              LOCAL GUARDIAN NUMBER{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="newRegisterInputBlockTwo">
                              <Controller
                                control={control}
                                name="familyNumber"
                                render={(field) => (
                                  <BillingAddInputField
                                    {...field}
                                    placeholder="Guardian Number"
                                    type="tele" // Changed to "tel" for telephone input
                                    newRegisterInput="newRegisterInput"
                                  />
                                )}
                                rules={{ required: true }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          DATE OF BIRTH <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            name="dateOfBirth"
                            control={control}
                            defaultValue=""
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <DatePicker
                                className={`newRegisterInput ${
                                  errors.dateOfBirth ? "error" : ""
                                }`} // Conditional class for error
                                selected={value ? new Date(value) : null}
                                onChange={(date) => {
                                  onChange(
                                    date
                                      ? moment(date).format("YYYY-MM-DD")
                                      : ""
                                  ); // Format and set value
                                }}
                                onBlur={onBlur} // Handle blur for validation
                                placeholderText="DD/MM/YYYY"
                                dateFormat="dd/MM/yyyy" // Format displayed in the date picker
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          DATE OF JOINING{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="dateOfJoining"
                            render={({ field }) => (
                              <DatePicker
                                // className="newRegisterInput"
                                className={`newRegisterInput ${
                                  errors.dateOfJoining ? "error" : ""
                                }`} // Conditional class for error
                                onChange={(date) => field.onChange(date)}
                                selected={field.value}
                                // dateFormat="YYYY/MM/DD"
                                // dateFormat='Pp/'
                                placeholderText="DD/MM/YY"
                                minDate={new Date(getTodayDate())} // Disable previous days
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          ROOM NO <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="roomNo"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Room No"
                                type="text"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          BED NUMBER
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="bedNo"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Bed Number"
                                type="text"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          SELECT FOOD OPTION{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            name="selectFoodOption"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Food option is required" }}
                            render={({ field }) => (
                              <select
                                {...field}
                                className={`newRegisterInput ${
                                  errors.selectFoodOption ? "error" : ""
                                }`}
                              >
                                {" "}
                                {/* Conditional class for error */}
                                <option value="">Select Food</option>
                                <option value="With Food">With Food</option>
                                <option value="Without Food">
                                  Without Food
                                </option>
                                <option value="Own Cooking">Own Cooking</option>
                              </select>
                            )}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          ADVANCE PAYMENT{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="advancePayment"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Advance Payment"
                                type="number"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          RENT FOR MONTH <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="rentForMonth"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Rent For Month"
                                type="number"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          ADDRESS
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="address"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Address"
                                type="text"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>

                      {/* <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          ADDRESS
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="address"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Address"
                                type="text"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>  
                      </div> */}
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          STATUS <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            name="status"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field }) => (
                              <select
                                {...field}
                                className={`newRegisterInput ${
                                  errors.status ? "error" : ""
                                }`}
                              >
                                <option value="">Status</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                              </select>
                            )}
                          />
                        </div>
                      </div>
                      {status == "Inactive" ? (
                        <>
                          <div className="register-container">
                            <label className="BillingAddInputLabel --new-register-label-two">
                              DATE OF LEAVING{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="newRegisterInputBlockTwo">
                              <Controller
                                control={control}
                                name="dateOfLeaving"
                                render={({ field }) => (
                                  <DatePicker
                                    className="newRegisterInput"
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                    placeholderText="DD/MM/YY"
                                    minDate={new Date(getTodayDate())} // Disable previous days
                                  />
                                )}
                                rules={{ required: true }}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-btn-block ">
                        <button className="register-submit-btn">
                          {isLoading ? (
                            <i class="fa fa-spinner fa-spin"></i>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PopUpModal isOpen={addRegisterPopup === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                onClick={() => {
                  setRegisterPopup(null);
                }}
              >
                <img
                  src="/assets/images/close.png"
                  className="popUpBackGround"
                />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="popUpContent p-2">Thank you!</span>
              <span className="popUpContentPara pb-3">
                User added successfully..
              </span>
              <button
                onClick={() => {
                  navigate("/viewRegister", { state: selectedHostel });
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal isOpen={roomStatus === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                onClick={() => {
                  setRegisterPopup(null);
                }}
              >
                <img
                  src="/assets/images/close.png"
                  className="popUpBackGround"
                />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center">
              {/* <span className="popUpContent p-2">Thank you!</span> */}
              <span className="popUpContentPara pb-3 pt-4">
                Room Not Available Please Check Available Room !!
              </span>
              <button
                onClick={() => {
                  navigate("/");
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal isOpen={showErrorPopUp === null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={() => {
              setErrorPopUp(null);
            }}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span className="popUpContentPara pt-3 pb-2">
            {errroMessage && errroMessage}
          </span>
          <button
            onClick={() => {
              setErrorPopUp(null);
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default NewRegister;
