import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PopupBox from "../popup/PopupBox";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import BillingAddInputField from "../BillingAddInputField";
import PopUpModal from "../PopUpModal";

const NewRoomForm = () => {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const { state } = useLocation();
  const storedBranchNames = localStorage.getItem("branchNames");
  const branchNameArray = JSON.parse(storedBranchNames) || [];

  // Set the initial state based on the branchNameArray
  const [selectedHostel, setSelectedHostel] = useState(
    (state && state) || branchNameArray[0] || ""
  );

  const [showErrorPopUp, setErrorPopUp] = useState(null);
  const [addRoomPopup, setAddRoomPopup] = useState(null);
  const [errroMessage, setErrorMessage] = useState("");
  // Handle the selection change
  const handleHostelChange = (e) => {
    const newValue = e.target.value;
    setSelectedHostel(newValue); // Update local state
    setValue("selectHostel", newValue); // Update react-hook-form field
  };

  useEffect(() => {
    if (state && state) {
      setSelectedHostel(state);
      setValue("selectHostel", state); // Update the react-hook-form field
    }
  }, [state, setValue, selectedHostel]);

  const { mutate, isLoading } = useMutation(
    (postData) =>
      axios.post(
        `${process.env.REACT_APP_HOST}/rooms/${selectedHostel}`,
        postData
      ),
    {
      onSuccess: () => {
        setAddRoomPopup(true);
        // reset();
      },
      onError: (error) => {
        if (error?.response) {
          const { data } = error?.response;
          setErrorMessage(data);
          setErrorPopUp(true);
        }
        else if(error?.response?.status === 500){
          alert(
            "Oops! Something went wrong on our end. Please try again later."
          );
        }
        return Promise.reject();
      },
    }
  );

  const onSubmit = (data) => {
    const formData = {
      roomNo: data?.roomNumber,
      noOfBeds: data?.NoOfBeds,
      ebNumber: data?.ebNumber,
      bedRoom: "",
      kitchen: "",
      hall: "",
      branchName: data?.branchName,
      noOfFloors: "",
      selectFloor: "",
      noOfRooms: "",
      noOfPortion: "",
      selectPortion: "",
      roomType: data?.roomType,
      selectHostel: data?.selectHostel,
      foodOption: "",
    };

    if (formData) {
      mutate(formData);
    }
  };

  return (
    <>
      <div className="row py-2 px-2 m-0 new-register-heading-block">
        <div className="col-12 col-sm-6 col-md-6 my-1 px-1">
          <div className="new-register-title-block">
            <img
              src="/assets/images/arrow-blue.png"
              alt="no-image"
              className="arrow-img-icon"
              onClick={() => {
                navigate(-1);
              }}
            />
            <h5 className="register-title pt-2">NEW ROOM</h5>
          </div>
        </div>
        <div className="col-12 col-sm-6 d-flex justify-content-between justify-content-md-end align-items-center gap-2 my-1">
          <Controller
            name="selectHostel"
            control={control}
            defaultValue={selectedHostel}
            rules={{ required: "This field is required" }}
            render={({ field }) => (
              <select
                {...field}
                value={selectedHostel}
                onChange={handleHostelChange}
                className={`selectDropDownNewRoom ${
                  errors.selectHostel ? "error" : ""
                }`}
              >
                <option value="">Branch Name</option>
                {branchNameArray.map((branchName) => (
                  <option key={branchName} value={branchName}>
                    {branchName}
                  </option>
                ))}
              </select>
            )}
          />
          <button
            className="view-register-btn"
            onClick={() => {
              navigate("/newRoom");
            }}
          >
            VIEW ROOM
          </button>
        </div>
      </div>

      <div className="new-room-form-main-block">
        <div className="new-room-form-block  my-3">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row m-0 p-0 mt-3 mb-2 align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">
                  BRANCH NAME{" "}
                  <span style={{ color: "red", fontSize: "16px" }}>*</span>
                </label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  name="branchName"
                  control={control}
                  defaultValue="SMHomes"
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <select
                      {...field}
                      className={`newRoomInputField ${
                        errors.branchName ? "error" : ""
                      }`}
                    >
                      <option value="">Branch Name</option>
                      <option value="SMHomes">SMHomes</option>
                    </select>
                  )}
                />
              </div>
            </div>
            <div className="row m-0 p-0 mt-2 mb-2 align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">
                  ROOM NUMBER{" "}
                  <span style={{ color: "red", fontSize: "16px" }}>*</span>
                </label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  control={control}
                  name="roomNumber"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Room Number"
                      type="text"
                      popupInputField="popup-input-field"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="row m-0 p-0 mt-2 mb-2 align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">
                  NO OF BEDS{" "}
                  <span style={{ color: "red", fontSize: "16px" }}>*</span>
                </label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  control={control}
                  name="NoOfBeds"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Sharing"
                      type="number"
                      popupInputField="popup-input-field"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="row m-0 p-0 mt-2 mb-2 align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">
                  ROOM TYPE{" "}
                  <span style={{ color: "red", fontSize: "16px" }}>*</span>
                </label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  name="roomType"
                  control={control}
                  defaultValue="With AC"
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <select
                      {...field}
                      className={`newRoomInputField ${
                        errors.branchName ? "error" : ""
                      }`}
                    >
                      <option value="">Room Type</option>
                      <option value="With AC">With AC</option>
                      <option value="Without AC">Without AC</option>
                    </select>
                  )}
                />
              </div>
            </div>
            <div className="row m-0 p-0 mt-2 mb-2 align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">EB NUMBER </label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  control={control}
                  name="ebNumber"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="EB Number"
                      type="text"
                      popupInputField="popup-input-field"
                    />
                  )}
                />
              </div>
            </div>
            <div className="row m-0 p-0 mt-2 mb-2 align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1"></div>
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <button type="submit" className="new-room-submit-btn">
                  {isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    "SUBMIT"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <PopUpModal isOpen={addRoomPopup == null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={() => {
              setAddRoomPopup(null);
            }}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span className="popUpContent p-2">Thank you!</span>
          <span className="popUpContentPara pb-2">
            Room added successfully..
          </span>
          <button
            onClick={() => {
              navigate("/viewRoom", { state: selectedHostel }); // Navigate first
              setAddRoomPopup(null); // Close the modal
              reset(); // Reset the form if necessary
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
      <PopUpModal isOpen={showErrorPopUp == null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={() => {
              setErrorPopUp(null);
            }}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span className="popUpContentPara pt-3 pb-2">
            {errroMessage && errroMessage}
          </span>
          <button
            onClick={() => {
              setErrorPopUp(null);

              reset();
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default NewRoomForm;
