import React from "react";
import HomePage from "../components/homecomp/HomePage";
import Sidebar from "../components/Sidebar";

const Home = () => {
  return (
    <>
      <section className="homeMainSection">
        <div className="container-fluid">
          <div className="row p-0">
            <div className="col-lg-2 p-0 m-0">
              <Sidebar />
            </div>
            <div className="col-lg-10 p-0 m-0">
              <HomePage />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
