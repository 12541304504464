import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import html2pdf from "html2pdf.js";
import { useLocation, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import { useMutation } from "react-query";
import axios from "axios";
const BillingInvoice = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  // console.log("state", state);
  // const downloadPDF = () => {
  //   const invoiceContent = document.getElementById("invoice").innerHTML;

  //   // Define options for the PDF generation
  //   const opt = {
  //     margin: 0.5,
  //     filename: "billing_invoice.pdf",
  //     image: { type: "jpeg", quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  //   };

  //   // Use html2pdf library to generate PDF from HTML content
  //   html2pdf().from(invoiceContent).set(opt).save();
  // };
  let billInfo = localStorage.getItem("billInfo");
  let role = localStorage.getItem("role");
  // let objdata[0] = JSON.parse(billInfo);
  const objdata = Object.assign({}, state?.userdata);
  // console.log("obj", objdata);
  const [pgbrances, setpgbrances] = useState("");

  useEffect(() => {
    if (objdata[0]?.shopName && objdata[0]?.shopName == "JskWomensPgOne") {
      setpgbrances("JSK WOMEN'S PG - 1");
    } else if (
      objdata[0]?.shopName &&
      objdata[0]?.shopName == "JskWomensPgTwo"
    ) {
      setpgbrances("JSK WOMEN'S PG - 2");
    } else if (
      objdata[0]?.shopName &&
      objdata[0]?.shopName == "JskWomensPgThree"
    ) {
      setpgbrances("JSK WOMEN'S PG - 3");
    }
  }, [pgbrances, objdata[0]?.shopName]);
  // console.log("Object data : ", objdata);
  const downloadInvoice = () => {
    const doc = new jsPDF("p", "mm", "a5"); // Create a new PDF in portrait mode (A5)

    // Add invoice title
    doc.setFontSize(16);
    doc.text("INVOICE", 74, 10, { align: "center" }); // Centered Title

    // Add company logo
    const imgData = "/assets/images/pg.jpg"; // Replace with your image path
    doc.addImage(imgData, "PNG", 6, 19, 25, 25); // Adjusted image position and size

    // Company details
    doc.setFontSize(12);
    doc.setFont("Arial", "bold");
    doc.text(`${pgbrances}`, 74, 25, {
      align: "center",
    });

    doc.setFontSize(10);
    doc.setFont("Arial", "normal");
    doc.text(`${objdata[0]?.companyAddress}`, 45, 35);
    doc.text(
      `${objdata[0]?.companyLocation}, Tamil Nadu ${objdata[0]?.companyPincode}`,
      45,
      41
    );
    doc.text(`Ph No: ${objdata[0]?.customerPhno}`, 112, 35); // Right-aligned
    doc.text(`${objdata[0]?.accountNumber}`, 122.8, 39.5);
    // Separator line
    doc.line(6, 50, 141, 50); // Line across the page

    // Bill details
    doc.setFontSize(10);
    // doc.text(`Bill.No: ${state?.item?.billNo}`, 10, 60);

    const labelX = 6; // X position for the label
    const valueX = 40; // X position for the value (adjust as needed)

    doc.text(`Bill.No`, labelX, 60); // Position for the label
    doc.text(` : ${state?.item?.billNo}`, valueX, 60);

    doc.text(`Name`, labelX, 68);
    doc.text(` : ${state?.item?.name}`, valueX, 68);

    doc.text(`Mobile Number`, labelX, 76);
    doc.text(` : ${state?.item?.mobileNumber}`, valueX, 76);

    doc.text(`Room No`, labelX, 84);
    doc.text(` : ${state?.item?.roomNo}`, valueX, 84);

    doc.text(`Date`, labelX, 92);
    doc.text(` : ${new Date().toLocaleDateString()}`, valueX, 92);

    // QR Code image
    const imgDatas = "/assets/images/qrscannercopys.jpg"; // Replace with your image path
    doc.addImage(imgDatas, "PNG", 101, 55, 35, 35); // Adjusted position and size

    // QR details
    doc.setFontSize(11);
    doc.setFont("Arial", "bold");
    doc.text(`UPI ID : qr.jskwomenpg@sib`, 90, 93);

    // Constants
    const headerY = 110; // Y position for the header
    const headerHeight = 10; // Height of the header
    const startX = 6; // Starting X position
    const padding = 2; // Padding before the first column

    // Define reduced column widths for A5
    const colWidths = {
      roomNo: 23, // Width for Room No
      name: 30, // Width for Name
      rent: 23, // Width for Rent For Month
      power: 23, // Width for Power Used
      others: 23, // Width for Others
      total: 23, // Width for Total
    };

    // Calculate X-positions based on reduced widths
    const xPositions = {};
    let currentX = startX + padding; // Start with padding

    for (const key in colWidths) {
      xPositions[key] = currentX;
      currentX += colWidths[key];
    }

    // Function to draw the header
    function drawHeader(doc) {
      // Set the fill color for the header
      doc.setFillColor(110, 196, 211); // RGB values for the background color
      doc.rect(startX, headerY - 7, 155 - 20, headerHeight, "F"); // Adjusted rectangle width for A5

      doc.setFontSize(10);
      doc.setFont("Arial", "bold");

      // Set header text using calculated X-positions
      doc.text("Room No", xPositions.roomNo, headerY);
      doc.text("Name", xPositions.name, headerY);
      doc.text("Rent", xPositions.rent, headerY);
      doc.text("EB Amount", xPositions.power, headerY);
      doc.text("Others", xPositions.others, headerY);
      doc.text("Total", xPositions.total, headerY);
    }

    // Function to draw a data row
    function drawRow(doc, data, yPosition) {
      doc.setFont("Arial", "normal");

      // Ensure each value is a string and handle potential undefined values
      doc.text(String(data?.roomNo || ""), xPositions.roomNo, yPosition);
      doc.text(String(data?.name || ""), xPositions.name, yPosition);
      doc.text(String(data?.rentForMonth || ""), xPositions.rent, yPosition);
      doc.text(String(data?.currentAmount || ""), xPositions.power, yPosition);
      doc.text(String(data?.others || ""), xPositions.others, yPosition);
      doc.text(String(data?.total || ""), xPositions.total, yPosition);
    }

    // Draw the header
    drawHeader(doc);

    // Example data row (you may replace this with dynamic data)
    const dataRow = {
      roomNo: state?.item?.roomNo,
      name: state?.item?.name,
      rentForMonth: state?.item?.rentForMonth,
      currentAmount: state?.item?.currentAmount,
      others: state?.item?.others,
      total: state?.item?.total,
    };

    // Draw the data row
    drawRow(doc, dataRow, 120);

    // Authorisation and footer
    doc.setFontSize(10);
    doc.setFont("Arial", "bold");
    doc.text("Authorised by", 118, 140);

    // Signature
    // Authorisation and footer
    doc.setFontSize(8);
    doc.setFont("Great Vibes", "italic");
    doc.text("Jsk Women's PG", 119, 147);

    // Footer message
    doc.setFontSize(10);
    doc.text("Thank You !!!", 74, 204, { align: "center" }); // Centered footer message

    doc.setFontSize(8);
    doc.setTextColor(173, 173, 173); // RGB for #adadad
    doc.text("www.2kvirtualworld.com", 115, 204); // Centered footer
    // Save the generated PDF
    doc.save("invoice.pdf");
  };
  const downloadPdf = () => {
    return new Promise((resolve, reject) => {
      const doc = new jsPDF("p", "mm", "a5"); // Create a new PDF in portrait mode (A5)

      // Add invoice title
      doc.setFontSize(16);
      doc.text("INVOICE", 74, 10, { align: "center" }); // Centered Title

      // Add company logo
      const imgData = "/assets/images/pg.jpg"; // Replace with your image path
      doc.addImage(imgData, "PNG", 6, 19, 25, 25); // Adjusted image position and size

      // Company details
      doc.setFontSize(12);
      doc.setFont("Arial", "bold");
      doc.text(`${pgbrances}`, 74, 25, {
        align: "center",
      });

      doc.setFontSize(10);
      doc.setFont("Arial", "normal");
      doc.text(`${objdata[0]?.companyAddress}`, 45, 35);
      doc.text(
        `${objdata[0]?.companyLocation}, Tamil Nadu ${objdata[0]?.companyPincode}`,
        45,
        41
      );
      doc.text(`Ph No: ${objdata[0]?.customerPhno}`, 112, 35); // Right-aligned
      doc.text(`${objdata[0]?.accountNumber}`, 122.8, 39.5);
      // Separator line
      doc.line(6, 50, 141, 50); // Line across the page

      // Bill details
      doc.setFontSize(10);
      // doc.text(`Bill.No: ${state?.item?.billNo}`, 10, 60);

      const labelX = 6; // X position for the label
      const valueX = 40; // X position for the value (adjust as needed)

      doc.text(`Bill.No`, labelX, 60); // Position for the label
      doc.text(` : ${state?.item?.billNo}`, valueX, 60);

      doc.text(`Name`, labelX, 68);
      doc.text(` : ${state?.item?.name}`, valueX, 68);

      doc.text(`Mobile Number`, labelX, 76);
      doc.text(` : ${state?.item?.mobileNumber}`, valueX, 76);

      doc.text(`Room No`, labelX, 84);
      doc.text(` : ${state?.item?.roomNo}`, valueX, 84);

      doc.text(`Date`, labelX, 92);
      doc.text(` : ${new Date().toLocaleDateString()}`, valueX, 92);

      // QR Code image
      const imgDatas = "/assets/images/qrscannercopys.jpg"; // Replace with your image path
      doc.addImage(imgDatas, "PNG", 101, 55, 35, 35); // Adjusted position and size

      // QR details
      doc.setFontSize(11);
      doc.setFont("Arial", "bold");
      doc.text(`UPI ID : qr.jskwomenpg@sib`, 90, 93);

      // Constants
      const headerY = 110; // Y position for the header
      const headerHeight = 10; // Height of the header
      const startX = 6; // Starting X position
      const padding = 2; // Padding before the first column

      // Define reduced column widths for A5
      const colWidths = {
        roomNo: 23, // Width for Room No
        name: 30, // Width for Name
        rent: 23, // Width for Rent For Month
        power: 23, // Width for Power Used
        others: 23, // Width for Others
        total: 23, // Width for Total
      };

      // Calculate X-positions based on reduced widths
      const xPositions = {};
      let currentX = startX + padding; // Start with padding

      for (const key in colWidths) {
        xPositions[key] = currentX;
        currentX += colWidths[key];
      }

      // Function to draw the header
      function drawHeader(doc) {
        // Set the fill color for the header
        doc.setFillColor(110, 196, 211); // RGB values for the background color
        doc.rect(startX, headerY - 7, 155 - 20, headerHeight, "F"); // Adjusted rectangle width for A5

        doc.setFontSize(10);
        doc.setFont("Arial", "bold");

        // Set header text using calculated X-positions
        doc.text("Room No", xPositions.roomNo, headerY);
        doc.text("Name", xPositions.name, headerY);
        doc.text("Rent", xPositions.rent, headerY);
        doc.text("EB Amount", xPositions.power, headerY);
        doc.text("Others", xPositions.others, headerY);
        doc.text("Total", xPositions.total, headerY);
      }

      // Function to draw a data row
      function drawRow(doc, data, yPosition) {
        doc.setFont("Arial", "normal");

        // Ensure each value is a string and handle potential undefined values
        doc.text(String(data?.roomNo || ""), xPositions.roomNo, yPosition);
        doc.text(String(data?.name || ""), xPositions.name, yPosition);
        doc.text(String(data?.rentForMonth || ""), xPositions.rent, yPosition);
        doc.text(
          String(data?.currentAmount || ""),
          xPositions.power,
          yPosition
        );
        doc.text(String(data?.others || ""), xPositions.others, yPosition);
        doc.text(String(data?.total || ""), xPositions.total, yPosition);
      }

      // Draw the header
      drawHeader(doc);

      // Example data row (you may replace this with dynamic data)
      const dataRow = {
        roomNo: state?.item?.roomNo,
        name: state?.item?.name,
        rentForMonth: state?.item?.rentForMonth,
        currentAmount: state?.item?.currentAmount,
        others: state?.item?.others,
        total: state?.item?.total,
      };

      // Draw the data row
      drawRow(doc, dataRow, 120);

      // Authorisation and footer
      doc.setFontSize(10);
      doc.setFont("Arial", "bold");
      doc.text("Authorised by", 118, 140);

      // Signature
      // Authorisation and footer
      doc.setFontSize(8);
      doc.setFont("Great Vibes", "italic");
      doc.text("Jsk Women's PG", 119, 147);

      // Footer message
      doc.setFontSize(10);
      doc.text("Thank You !!!", 74, 204, { align: "center" }); // Centered footer message

      doc.setFontSize(8);
      doc.setTextColor(173, 173, 173); // RGB for #adadad
      doc.text("www.2kvirtualworld.com", 115, 204); // Centered footer
      // Save the generated PDF
      // doc.save("invoice.pdf");
      // Generate the PDF as a Blob
      const pdfBlob = doc.output("blob");

      // Resolve the Blob so it can be uploaded
      resolve(pdfBlob);
    });
  };

  const printInvoice = () => {
    const printContent = document.getElementById("invoice").innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Invoice</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 20px; }
            img { max-width: 20%; height: 20%; }
          </style>
        </head>
        <body onload="window.print(); window.close();">
          ${printContent}
        </body>
      </html>
    `);
    printWindow.document.close();
  };
  const [ispdf, setIsPdf] = useState("File");
  const { mutate, isLoading, isError, isSuccess, data, error } = useMutation(
    (postData) =>
      axios.post(`${process.env.REACT_APP_HOST}/api/invoices/upload`, postData),
    {
      onSuccess: (data) => {
        console.log("file uploaded to server");
      },
      onError: (error) => {
        console.log("error uploading file");
      },
    }
  );

  // const shareOnWhatsApp = async () => {
  //   // Generate the PDF blob
  //   const pdfBlob = await downloadPdf();

  //   // WhatsApp URL with a pre-filled message
  //   const whatsappUrl =
  //     "https://api.whatsapp.com/send?phone=9159950439&text=Here%20is%20your%20invoice%20download%20link%3A%20https%3A%2F%2Fexample.com%2Ffile.pdf";

  //   // Open WhatsApp with the pre-filled message
  //   window.open(whatsappUrl, "_blank");
  // };
  // Function to handle the WhatsApp sharing
  const shareOnWhatsApp = async () => {
    try {
      // Generate the PDF blob
      const pdfBlob = await downloadPdf();

      // Create FormData for file upload
      const formData = new FormData();
      formData.append("file", pdfBlob, "invoice.pdf");

      // Upload the PDF to the server and get the file URL
      mutate(formData, {
        onSuccess: (response) => {
          console.log("File uploaded successfully:", response);

          // Assuming the server returns the file download URL in response.data
          let fileUrl = response?.data || "";

          if (!fileUrl) {
            console.error("No file URL received from the server");
            return;
          }

          // Replace the base URL (localhost) with the new base URL
          const newBaseUrl = "https://api.2kvirtualworld.com/HOSTELBILLINGDEV";
          fileUrl = fileUrl?.replace(
            "http://localhost:8080/hostel",
            newBaseUrl
          );

          // WhatsApp message with the modified download URL
          const message = `Here is your invoice. Click here to download: ${fileUrl}`;

          // Construct the WhatsApp URL with the pre-filled message
          const whatsappUrl = `https://api.whatsapp.com/send?phone=${
            state?.item?.mobileNumber
          }&text=${encodeURIComponent(message)}`;

          // Open WhatsApp with the pre-filled message
          window.open(whatsappUrl, "_blank");
        },
        onError: (error) => {
          console.log("Error uploading file:", error);
        },
      });
    } catch (error) {
      console.error("Error generating or sharing invoice:", error);
    }
  };

  return (
    <>
      <section className="billingInvoiceSection">
        <div className="container">
          <div className="row px-3 m-0">
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <img
                  src="/assets/images/back-arrow.png"
                  className="arrow-img-icon"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <h6 className="rentBillHeading pt-2">INVOICE</h6>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-start justify-content-md-end align-items-center gap-1">
              <button
                className="btn bg-primary text-white"
                onClick={() => shareOnWhatsApp()}
              >
                Share
              </button>
              &nbsp;
              <button
                className="btn bg-primary text-white"
                onClick={() => printInvoice()}
              >
                Print
              </button>
              &nbsp;
              <button
                className="btn bg-primary text-white"
                onClick={downloadInvoice}
              >
                Download
              </button>
            </div>
            <div className="col-lg-12" id="invoice">
              <div className="col-12 d-flex justify-content-center p-0 m-0">
                <h2 className="py-2">INVOICE</h2>
              </div>

              <div className="row p-0 m-0">
                <div className="col-sm-4">
                  <div className="billing-logo">
                    <img
                      src="/assets/images/pg.jpg"
                      className="image_icon"
                      alt="no-image"
                    />
                  </div>
                </div>
                <div className="col-sm-4 py-2 d-flex justify-content-start align-items-center justify-content-sm-center">
                  <div>
                    <h3 className="company_name_shopename text-center">
                      {pgbrances?.toUpperCase()}
                    </h3>
                    <p className="billing-invoice-para my-2">
                      {objdata[0]?.companyAddress} {objdata[0]?.companyLocation}
                      &nbsp; TamilNadu {objdata[0]?.companyPincode}
                    </p>
                  </div>
                </div>
                <div className="col-sm-4 d-flex justify-content-start align-items-center justify-content-sm-center">
                  <p className="billing-invoice-para d-flex flex-column p-0 m-0">
                    <span> Ph: {objdata[0]?.customerPhno}</span>
                    <span>Alt Ph: {objdata[0]?.accountNumber}</span>
                  </p>
                </div>
              </div>

              <div className="mt-2 logoBlock px-2">
                <div className="billing-invoice-stu-block">
                  <div className="d-flex flex-column">
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "150px" }}>
                        <span className="invoicePara">Bill.No</span>
                      </div>
                      <span className="invoice_Para">
                        : {state?.item?.billNo}
                      </span>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "150px" }}>
                        <span className="invoicePara">Name </span>
                      </div>
                      <span className="invoice_Para">
                        : {state?.item?.name}
                      </span>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "150px" }}>
                        <span className="invoicePara">Mobile Number</span>
                      </div>
                      <span className="invoice_Para">
                        : {state?.item?.mobileNumber}
                      </span>
                    </div>

                    <div style={{ display: "flex" }}>
                      <div style={{ width: "150px" }}>
                        <span className="invoicePara">RoomNo</span>
                      </div>
                      <span className="invoice_Para">
                        : {state?.item?.roomNo}
                      </span>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "150px" }}>
                        <span className="invoicePara">Date</span>
                      </div>
                      <span className="invoice_Para">
                        : {state?.item?.date}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="billing-invoice-acc-block">
                  <img
                    src="/assets/images/qrscannercopy.jpg"
                    alt="QR Code"
                    className="img-fluid"
                    width="300px"
                    height="300px"
                  />
                </div>
              </div>
              <div className="tableData py-3">
                <TableContainer>
                  <Table>
                    <TableHead className="billingInvoiceHeader">
                      <TableRow>
                        <TableCell className="billingInvoiceHeading">
                          RoomNo
                        </TableCell>
                        <TableCell className="billingInvoiceHeading">
                          Name
                        </TableCell>
                        <TableCell className="billingInvoiceHeading">
                          Rent For Month
                        </TableCell>
                        <TableCell className="billingInvoiceHeading">
                          Power Used
                        </TableCell>
                        <TableCell className="billingInvoiceHeading">
                          Others
                        </TableCell>
                        <TableCell className="billingInvoiceHeading">
                          Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        <TableRow>
                          <TableCell className="billingInvoiceData">
                            {state?.item?.roomNo}
                          </TableCell>
                          <TableCell className="billingInvoiceData">
                            {state?.item?.name}
                          </TableCell>
                          <TableCell className="billingInvoiceData">
                            {state?.item?.rentForMonth}
                          </TableCell>
                          <TableCell className="billingInvoiceData">
                            {state?.item?.currentAmount}
                          </TableCell>
                          <TableCell className="billingInvoiceData">
                            {state?.item?.others}
                          </TableCell>
                          <TableCell className="billingInvoiceData">
                            {state?.item?.total}
                          </TableCell>
                        </TableRow>
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className="d-flex pt-5 my-4 flex-column align-items-end">
                <p className="invoice_Para">Authorised by</p>

                <i>Jsk Women's PG</i>
              </div>
              <div className="row py-2">
                <div className="col-lg-12 d-flex justify-content-center">
                  <i>Thank You !!!</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BillingInvoice;
