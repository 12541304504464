import React, { useRef, useEffect } from "react";
// import close from "../../assets/images/close.png";
const PopupModel = ({
  isOpen,
  onClose,
  children,
  maxWidth,
  backgroundColor,
}) => {
  if (!isOpen) return null;
  return (
    <>
      <div className="popup-overlay">
        <div className="popup-content">
          {children}
        </div>
      </div>
    </>
  );
};

export default PopupModel;
