import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

export default function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      {/* Hamburger Icon */}
      <button
        className={isOpen ? "hamburger-icon" : "hamburger_icon"}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <img
            src="/assets/images/close.png"
            style={{ width: "23px", height: "23px" }}
            alt="no-image"
          />
        ) : (
          <img
            src="/assets/images/hamburger.png"
            style={{ width: "23px", height: "23px" }}
            alt="no-image"
          />
        )}
      </button>
      {/* Sidebar */}
      <div className={`sidebar${isOpen ? " open" : ""}`}>
        <div className="sidebar-header">
          <a
            href="/"
            className="d-flex m-3 align-items-center gap-1 text-decoration-none sidebar_logo"
          >
            <img
              src="/assets/images/2k-logo.png"
              className="img-fluid sidebar-image"
              alt="no-image"
              loading="lazy"
            />
            <span className="sidebar-head">2K Inventory</span>
          </a>
        </div>
        <hr />
        <div className="d-flex flex-column align-items-start justify-content-between">
          <ul
            className="nav nav-pills flex-column mb-auto"
            style={{ width: "100%" }}
          >
             <li>
              <NavLink to="/homePage" className="nav-label d-block" aria-current="page">
               Home Page
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/newRoom"
                className="nav-label d-block"
                aria-current="page"
              >
                New Room
              </NavLink>
            </li>
            <li>
              <NavLink to="/viewRoom" className="nav-label d-block" aria-current="page">
                View Room
              </NavLink>
            </li>
            <li>
              <NavLink to="/newRegister" className="nav-label d-block">
                New Register
              </NavLink>
            </li>
            <li>
              <NavLink to="/viewRegister" className="nav-label d-block">
                View Register
              </NavLink>
            </li>
            <li>
              <NavLink to="/newBill" className="nav-label d-block">
                New Bill
              </NavLink>
            </li>
            <li>
              <NavLink to="/viewBill" className="nav-label d-block">
                View Bill
              </NavLink>
            </li>
          </ul>
          <a
            href="/"
            className="d-flex m-3 align-items-center gap-2 text-decoration-none"
            onClick={() => {
              localStorage.removeItem("login");
              // localStorage.removeItem("billInfo");
              localStorage.removeItem("branchNames");
              localStorage.removeItem("registers");
              localStorage.clear();

              navigate("/");
              window.location.reload();
            }}
          >
            <img
              src="/assets/images/logout.png"
              alt="no-image"
              style={{ width: "25px", height: "25px" }}
            />
            <span style={{ color: "white", fontWeight: "600" }}>Logout</span>
          </a>
        </div>
      </div>
    </>
  );
}
