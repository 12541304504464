import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import BillingAddInputField from "../BillingAddInputField";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useMutation } from "react-query";
import axios from "axios";
import { useLocation } from "react-router-dom";
import PopUpModal from "../PopUpModal";
const Editroom = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const [updatePopup, setUpdatePopup] = useState(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const storedBranchNames = localStorage.getItem("branchNames");
  const branchNameArray = JSON.parse(storedBranchNames) || [];

  // Set the initial state based on the branchNameArray
  const [selectedHostel, setSelectedHostel] = useState(
    state?.selectHostel || branchNameArray[0] || ""
  );
  const [selectedFloor, setSelectedFloor] = useState("");
  const [roomCounts, setRoomCounts] = useState({});
  const [noOfFloors, setNoOfFloors] = useState(0);

  console.log("STATE", state);

  useEffect(() => {
    setValue("roomNumber", state?.roomNo);
    setValue("NoOfBeds", state?.noOfBeds);
    setValue("roomType", state?.roomType);
    setValue("ebNo", state?.ebNumber);
    setValue("foodOption", state?.foodOption);
    // setValue("selectHostel", state?.selectHostel);
    if (state?.selectHostel) {
      setSelectedHostel(state?.selectHostel);
      setValue("selectHostel", state?.selectHostel); // Update the react-hook-form field
    }
  }, []);
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postdata) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/rooms/updateNewRoom/${selectedHostel}/${
          state && state?.id
        }`,
        postdata
      ),
    {
      onSuccess: (val) => {
        console.log("data updated successfully...");
        setUpdatePopup(1);
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );

  useEffect(() => {
    if (state) {
      setValue("branchName", state?.branchName);
      setValue("noOfFloors", state?.noOfFloors);
      setValue("selectFloors", state?.selectFloor);
      setValue("noOfRooms", state?.noOfRooms);
      setValue("ebNumber", state?.ebNumber);
      setValue("selectHostel", state?.selectHostel);
    }
  }, [state, setValue]);
  const onSubmit = (data) => {
    let formData;

    if (state && state.id != null && selectedHostel) {
      formData = {
        roomNo: data?.roomNumber,
        noOfBeds: data?.NoOfBeds,
        ebNumber: data?.ebNumber,
        branchName: state?.branchName,
        noOfFloors: state?.noOfFloors,
        selectFloor: state?.selectFloors,
        noOfRooms: state?.noOfRooms,
        bedRoom: state?.bedRoom,
        kitchen: state?.kitchen,
        hall: state?.hall,
        roomType: data?.roomType,
        selectHostel: data?.selectHostel,
        foodOption: "",
        noOfPortion: state?.noOfPortion,
        selectPortion: state?.selectPortion,
      };
    }
    mutate(formData);
  };

  return (
    <>
      <div className="row py-3 px-2 m-0 new-register-heading-block">
        <div className="col-12 col-sm-6 col-md-6">
          <div className="new-register-title-block">
            <img
              src="/assets/images/arrow-blue.png"
              alt="no-image"
              className="arrow-img-icon"
              onClick={() => {
                navigate(-1);
              }}
            />
            <h5 className="register-title pt-2">EDIT ROOM</h5>
          </div>
        </div>
        <div className="col-12 col-sm-6 d-flex  justify-content-md-end  gap-2">
          <Controller
            name="selectHostel"
            control={control}
            defaultValue={selectedHostel}
            rules={{ required: "This field is required" }}
            render={({ field: { onChange } }) => (
              <select
                value={selectedHostel}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSelectedHostel(newValue); // Update local state
                  onChange(newValue); // Update react-hook-form field
                }}
                className={`selectDropDownNewRoom ${
                  errors.selectHostel ? "error" : ""
                }`}
              >
                <option value="">Branch Name</option>
                {branchNameArray.map((branchName) => (
                  <option key={branchName} value={branchName}>
                    {branchName}
                  </option>
                ))}
              </select>
            )}
          />
        </div>
      </div>
      <div className="register-form-main-block">
        <div className="new-room-form-block my-3">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row m-0 p-0 mt-3 mb-2 align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">
                  BRANCH NAME{" "}
                  <span style={{ color: "red", fontSize: "16px" }}>*</span>
                </label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  name="branchName"
                  control={control}
                  defaultValue="JSK WOMENS PG"
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <select
                      {...field}
                      className={`newRoomInputField ${
                        errors.branchName ? "error" : ""
                      }`}
                    >
                      <option value="">Branch Name</option>
                      <option value="JSK WOMENS PG">JSK WOMENS PG</option>
                    </select>
                  )}
                />
              </div>
            </div>
            <div className="row m-0 p-0 mt-2 mb-2 align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">
                  ROOM NUMBER{" "}
                  <span style={{ color: "red", fontSize: "16px" }}>*</span>
                </label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  control={control}
                  name="roomNumber"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Room Number"
                      type="text"
                      popupInputField="popup-input-field"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="row m-0 p-0 mt-2 mb-2 align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">
                  NO OF BEDS{" "}
                  <span style={{ color: "red", fontSize: "16px" }}>*</span>
                </label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  control={control}
                  name="NoOfBeds"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Sharing"
                      type="number"
                      popupInputField="popup-input-field"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="row m-0 p-0 mt-2 mb-2 align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">
                  ROOM TYPE{" "}
                  <span style={{ color: "red", fontSize: "16px" }}>*</span>
                </label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  name="roomType"
                  control={control}
                  defaultValue="With AC"
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <select
                      {...field}
                      className={`newRoomInputField ${
                        errors.branchName ? "error" : ""
                      }`}
                    >
                      <option value="">Room Type</option>
                      <option value="With AC">With AC</option>
                      <option value="Without AC">Without AC</option>
                    </select>
                  )}
                />
              </div>
            </div>
            <div className="row m-0 p-0 mt-2 mb-2 align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">EB NUMBER </label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  control={control}
                  name="ebNumber"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="EB Number"
                      type="text"
                      popupInputField="popup-input-field"
                    />
                  )}
                />
              </div>
            </div>
            <div className="row m-0 p-0 mt-2 mb-2 align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1"></div>
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <button type="submit" className="new-room-submit-btn">
                  {isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    "SUBMIT"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <PopUpModal isOpen={updatePopup === null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={() => {
              setUpdatePopup(null);
            }}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>
        <div className=" pt-2 d-flex flex-column justify-content-center align-items-center">
          <p className="popupDeleteUser  m-0 p-o">
            Room has been updated successfully..
          </p>
          <button
            onClick={() => {
              navigate("/viewRoom", { state: selectedHostel });
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default Editroom;
