import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Sidebar from "../Sidebar";
import { useQuery } from "react-query";
import axios from "axios";
import PopupModel from "../popup/PopupModel";
// import { getViewRegister } from "../../globalAPI/Api";
const ViewBeds = () => {
  const { state } = useLocation();
  console.log("STATE", state);
  const [popupopen, setPopupopen] = useState(false);
  const [userdetails, setUserdetails] = useState();
  const navigate = useNavigate();
  const getViewRegister = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/newregister/getAllNewRegister/${state?.selectHostel}`
    );
  };
  const { data, isLoading, isError } = useQuery(
    ["getViewRegister", state?.selectHostel],
    getViewRegister
  );
  let details = data && data?.data;
  console.log("DETAILS", details);
  let filteredDetails;
  let i = 0;
  if (Array.isArray(details) && state?.roomNo != null) {
    // Filter the details array to include only the objects with the matching roomNo
    filteredDetails = details?.filter((item) => item?.roomNo === state?.roomNo);
    // console.log("MAPPED DETAILS", filteredDetails);
  }
  const handlePopup = (item) => {
    setPopupopen(true);
    setUserdetails(item);
  };
  // console.log("userdetails", userdetails);
  return (
    <>
      <section className="viewBillMainSection">
        <div className="container-fluid">
          <div className="row p-0">
            <div className="col-lg-2 p-0 m-0">
              <Sidebar />
            </div>
            <div className="col-lg-10  p-0 m-0">
              <div className="view-beds-heading-block">
                <div className="view-beds-head">
                  <img
                    src="/assets/images/arrow-blue.png"
                    style={{ width: "40px", height: "40px", cursor: "pointer" }}
                    className="arrow-img-icon"
                    alt="no-image"
                    onClick={() => navigate(-1)}
                  />
                  <h5 className="register-title pt-2">VIEW BED'S</h5>
                </div>
              </div>
              <div className="view-room-block">
                <div className="view-bed-main-block">
                  <h6 className="view-beds-title">
                    ROOM NUMBER : {state?.roomNo}
                  </h6>
                  <div className="beds-card-block">
                    {filteredDetails?.length == 0 ? (
                      <>
                        <div
                          class="card text-center card-block"
                          onClick={() => {
                            navigate("/newRegister", { state: state });
                          }}
                        >
                          <div class="card-body view-beds-add-block">
                            <img
                              src="/assets/images/addIcon.png"
                              alt="no-image"
                            />
                            <h6 className="card-content-para">ADD USER</h6>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {filteredDetails &&
                          filteredDetails?.map((e, i) => {
                            return (
                              <>
                                <div
                                  class="card text-center  card-block"
                                  key={i}
                                  onClick={() => {
                                    handlePopup(e);
                                  }}
                                >
                                  <div class="card-body d-flex justify-content-center align-items-center flex-column">
                                    <h6 className="d-flex gap-3 p-0 m-0">
                                      <span class="card-title card-content-para">
                                        {e?.roomNo} B{e?.bedNo} - {e?.name} -{" "}
                                        {e?.status}
                                      </span>
                                    </h6>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        {(state && state?.status == "available") ||
                        (state && state?.status == "Available") ? (
                          <>
                            <div
                              class="card text-center card-block"
                              onClick={() => {
                                navigate("/newRegister", {
                                  state: state,
                                });
                              }}
                            >
                              <div class="card-body d-flex align-items-center justify-content-center">
                                <img
                                  src="/assets/images/addIcon.png"
                                  alt="no-image"
                                />
                                <h6 className="card-content-para">ADD USER</h6>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PopupModel isOpen={popupopen}>
        {userdetails && (
          <>
            <div
              className="close-icon-block"
              onClick={() => {
                setPopupopen(false);
              }}
            >
              <img
                src="../assets/images/close.png"
                alt="no-image"
                className="search-icon"
              />
            </div>
            <div className="popup-box">
              <div className="tech-details">
                <div className="popup-heading-block">
                  <div className="popup-logo-block">
                    <img
                      src="/assets/images/2k-logo.png"
                      alt="no-image"
                      className="crm-image"
                    />
                  </div>
                  <span className="popup-logo-heading">2K SERVICE</span>
                </div>
                <div className="popup-profile-block">
                  <div className="popup-profile">
                    <img
                      src="/assets/images/userpic.png"
                      alt="no-image"
                      className="crm-image"
                    />
                  </div>
                </div>
                <p className="tech-user-details">
                  {" "}
                  NAME : {userdetails?.name.toUpperCase()}
                </p>
                <p className="tech-user-details">
                  EMAIL ID : {userdetails?.emailId}
                </p>
                <p className="tech-user-details">
                  MOBILE NUMBER : {userdetails?.mobileNo}
                </p>
              </div>
              <div className="request-details">
                <div className="request-user-details mt-5">
                  <p className="request-key">ROOM NO </p>{" "}
                  <p className="request-value">: {userdetails?.roomNo}</p>
                </div>

                <div className="request-user-details">
                  <p className="request-key">DATE OF JOINING </p>{" "}
                  <p className="request-value">
                    : {userdetails?.dateOfJoining}
                  </p>
                </div>
                <div className="request-user-details">
                  <p className="request-key">DATE OF BIRTH </p>{" "}
                  <p className="request-value">: {userdetails?.dateOfBirth}</p>
                </div>
                <div className="request-user-details">
                  <p className="request-key">FOOD OPTION </p>{" "}
                  <p className="request-value">
                    {" "}
                    : {userdetails?.selectFoodOption}
                  </p>
                </div>
                <div className="request-user-details">
                  <p className="request-key">ADVANCE AMT </p>{" "}
                  <p className="request-value">
                    {" "}
                    : {userdetails?.advanceAmount}
                  </p>
                </div>
                <div className="request-user-details">
                  <p className="request-key">MONTH RENT </p>{" "}
                  <p className="request-value">
                    {" "}
                    : {userdetails?.rentForMonth}
                  </p>
                </div>
                <div className="request-user-details">
                  <p className="request-key">STATUS</p>{" "}
                  <p className="request-value">: {userdetails?.status}</p>
                </div>
                {/* <div className="request-user-details">
                  <p className="request-key">DATE OF LEAVING</p>{" "}
                  <p className="request-value">
                    : {userdetails?.dateOfLeaving}
                  </p>
                </div> */}
              </div>
            </div>
          </>
        )}
      </PopupModel>
    </>
  );
};

export default ViewBeds;
